@font-face {
    font-family: 'Roboto-Regular';
    src: url('../fonts/Roboto-Regular.ttf');
  }
  
  @font-face {
    font-family: 'Roboto-Thin';
    src: url('../fonts/Roboto-Thin.ttf');
  }
  
  @font-face {
    font-family: 'Roboto-Light';
    src: url('../fonts/Roboto-Light.ttf');
  }
  
  @font-face {
    font-family: 'Roboto-Regular';
    src: url('../fonts/Roboto-Medium.ttf');
  }
  
  @font-face {
    font-family: 'Roboto-Bold';
    src: url('../fonts/Roboto-Bold.ttf');
  }
  
  @font-face {
    font-family: 'Roboto-Black';
    src: url('../fonts/Roboto-Black.ttf');
  }
  