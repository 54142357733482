/* You can add global styles to this file, and also import other style files */
html,
body,
form,
fieldset,
table,
tr,
td,
img {
    margin: 0;
    padding: 0;

}

input,
button,
select,
textarea,
optgroup,
option {
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
}

body {
    font-family: Roboto-Regular;
}