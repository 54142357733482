$font-14: 14px;
$font-12: 12px;
$font-11: 11px;
$font-16: 16px;
$font-18: 18px;
$font-20: 20px;

$font-family: 'Roboto-Regular';
$font-family_1: 'Roboto-Regular';
$font-family_2: 'Roboto-Light';
$font-family_2: 'Roboto-Thin';

$color_1: #ffffff;
$color_2: #000000;
$color_3: #1D1F21;
$color_4: #CF202A;
$color_5: #797276;
$color_6: #CCCCCC;
$color_7: #808080;
$color_8: #D9D9D9;
$color_9: #999;
$color_10: #04af04;

$background-color_1: #F8F8F8;
$background-color_2: #000000;
$background-color_3: #ffffff;
$background-color_4: #F7F7F7;

$box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
$border-color-1: #dddddd;

//-------- Hide/Show class -----------
.hide{
    display: none;
}
.show{
    display: block;
}
//-------- margin padding -----------
.mr-5{
    margin-right: 5px;
}
.ml-5{
    margin-left: 5px;
}
.ml-50{
    margin-left: 50px;
}
.mt-15 {
    margin-top: 15px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-40 {
    margin-top: 40px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-40 {
    margin-bottom: 40px;
}

//-------- font weight -----------
.t-bold {
    font-weight: 700;
}

.t-light {
    font-weight: 400;
}

//-------- text align -----------

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

//-------- position -----------------
.position-rel {
    position: relative;
}

//--------- error/ success class ----
.error {
    color: #ce1126;
}

.danger {
    color: green;
}

.red {
    color: #ce1126;
}

//-------- flex -----------------

.flex {
    display: flex;
}

.flex-column {
    flex-direction: column;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-h-center {
    justify-content: center;
}

.flex-v-center {
    align-items: center;
}

.flex-h-v-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-space-between {
    justify-content: space-between;
}

/*-----loader------*/
.universal-loader {
    position: absolute;
    background-color: white;
    width: 100%;
    height: 100%;
    opacity: 0.75;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;

    svg {
        transform: scale(3);

        circle {
            stroke: #ce1126 !important;
        }
    }
}

.loader {
    width: auto;
    display: flex;
    justify-content: center;
    padding: 50px;

    svg {
        transform: scale(1.5);
    }
}

/* --- animation rotate ---*/

.a-rotate {
    animation: rotate-360 30s infinite;
}

@keyframes rotate-360 {
    100% {
        transform: rotate(720deg);
    }
}

/*-----card------*/
.h-card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 30%;

    &.h-card:hover {
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
        transform: scale(1.1);
    }

    .h-card-details {
        font-size: $font-14;
        color: $color_3;
        padding: 2px 16px;
    }
}

/*--- SCL slect error class ---*/

ryui-select.error {

    .ryder-ui {

        .ryder-ui__select-fluid {
            border-color: #ce1126 !important;
        }

        .ryder-ui__helper {
            ryui-helper-text {
                color: #ce1126 !important;

                &>div>span {
                    padding-left: 20px;
                    font-size: $font-14;
                    background-repeat: no-repeat;
                    background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" class="svg-inline--fa fa-triangle-exclamation" data-icon="triangle-exclamation" data-prefix="fas" focusable="false" aria-hidden="true" role="img"><path fill="rgb(206 17 38)" d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"></path></svg>');
                }
            }
        }
    }
}

/*--- SCL slect error class Ends ---*/

/* ------- icons ------ @include getIcon(circle, green); color must be in english not in #000*/

@mixin getIcon($iconName, $color) {
    height: 16px;
    width: 16px;
    background-repeat: no-repeat;
    @if $iconName ==circle {
        background-image: url('data:image/svg+xml;utf8,<svg fill="#{$color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200z"/></svg>');
    }

    @else if $iconName ==checked-circle {
        background-image: url("data:image/svg+xml;utf8,<svg fill='#{$color}' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path d='M504 256c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zM227.3 387.3l184-184c6.2-6.2 6.2-16.4 0-22.6l-22.6-22.6c-6.2-6.2-16.4-6.2-22.6 0L216 308.1l-70.1-70.1c-6.2-6.2-16.4-6.2-22.6 0l-22.6 22.6c-6.2 6.2-6.2 16.4 0 22.6l104 104c6.2 6.2 16.4 6.2 22.6 0z'/></svg>");
    }

    @else if $iconName ==info {
        background-image: url('data:image/svg+xml;utf8,<svg fill="#{$color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 8C119 8 8 119.1 8 256c0 137 111 248 248 248s248-111 248-248C504 119.1 393 8 256 8zm0 110c23.2 0 42 18.8 42 42s-18.8 42-42 42-42-18.8-42-42 18.8-42 42-42zm56 254c0 6.6-5.4 12-12 12h-88c-6.6 0-12-5.4-12-12v-24c0-6.6 5.4-12 12-12h12v-64h-12c-6.6 0-12-5.4-12-12v-24c0-6.6 5.4-12 12-12h64c6.6 0 12 5.4 12 12v100h12c6.6 0 12 5.4 12 12v24z"/></svg>');
    }
}

.info-icon {
    @include getIcon(info, grey);
}

/* ------- icons end ------ */


/* ------- stepper ------ */
.stepper-container {
    list-style: none;
    padding: 0px;

    .stepper-steps {
        display: flex;
        align-items: center;
        font-size: $font-14;
        height: 50px;
        padding-left: 20px;
        padding-right: 20px;
        border-top: 1px solid $color_8;
        border-right: 1px solid $color_8;

        &:last-child {
            border-right: none;
        }

        .stepper-icon {
            &.checked {
                @include getIcon(checked-circle, green);
            }

            &.unchecked {
                @include getIcon(circle, grey);
            }

        }

        span {
            margin-left: 10px;
        }

    }
}

/* ------- stepper ends ------ */


/* ------- input time picker ------ */
.time-picker {
    width: 140px;

    label {
        position: relative;
        top: -4px;
        font-size: 14px;
        background: transparent;
        padding: 0 2px;
        opacity: 1;
        color: $color_9;
        pointer-events: none;
    }

    .helper-text {
        display: flex;
        align-items: center;
        font-family: Roboto-Regular;
        font-size: 13px;
        font-weight: 400;
        line-height: 154%;
        text-transform: none;
        letter-spacing: .4px;
        color: #ce1126;
    }
}


input[type=time] {
    border: 1px solid grey;
    color: #2a2c2d;
    font-size: 14px;
    font-family: helvetica;
    min-width: 135px;
    height: 47px;
    border-radius: 5px;
}

/* Wrapper around the hour, minute, second, and am/pm fields */
input[type=time]::-webkit-datetime-edit-fields-wrapper {
    display: flex;
}

/* The space between the fields - between hour and minute and am/pm */
input[type=time]::-webkit-datetime-edit-text {
    padding: 19px 4px;
}

/* The naming convention for the hour, minute, second, and am/pm field is
  `-webkit-datetime-edit-{field}-field` */

/* Hour */
input[type=time]::-webkit-datetime-edit-hour-field {
    background-color: #ffffff;
    border-radius: 15%;
    padding: 19px 13px;
    color: black;
}

/* Minute */
input[type=time]::-webkit-datetime-edit-minute-field {
    background-color: #ffffff;
    border-radius: 15%;
    padding: 19px 13px;
    color: black;
}

/* AM/PM */
input[type=time]::-webkit-datetime-edit-ampm-field {
    background-color: #7155d3;
    border-radius: 15%;
    color: #fff;
    padding: 19px 13px;
}

/* 'X' button for resetting/clearing time */
input[type=time]::-webkit-clear-button {
    display: none;
}

/* Up/Down arrows for incrementing/decrementing the value */
input[type=time]::-webkit-inner-spin-button {
    display: none;
}

/* watch icon */
input[type="time"]::-webkit-calendar-picker-indicator {
    //filter: invert(0.5) sepia(1) saturate(5) hue-rotate(175deg);
    font-size: 18px;
    opacity: 0.7;
    padding: 10px;
}


/* ------- input time ends ------ */