@import 'assets/styles/font';
@import 'assets/styles/init';
@import 'assets/styles/commonclass';

.link {
    font-family: $font-family_1;
    font-size: $font-14;
    font-weight: 400;
    line-height: 143%;
    text-transform: none;
    letter-spacing: .16px;
    outline: none;
    text-decoration: none;
    border-radius: 4px;
    padding-bottom: 9px;
}

ryui-snackbar {
    width: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    z-index: 99;
    top: 10px;
}

.field-duplicate-space {
    width: 213px;
    height: 40px;
    display: block;
}

.nav-box-wrapper {

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    min-height: 100vh;
    width: 100%;
    position: relative;
    float: left;
}

.dashboardcontrol {
    background-color: $background-color_4;

    .dashboardcontrol-top-bar {


        &.ryder-ui--top-bar_light {

            kendo-appbar {
                kendo-appbar-section:nth-child(1) {
                    display: none;
                }
            }

            ryui-brand {
                svg.inverted {
                    background-color: #fff;

                    .ryder-ui--brand--title {
                        fill: #ce1126 !important;
                    }

                    .ryder-ui--brand--sub-title {
                        fill: #000 !important;
                    }

                    .ryder-ui--brand--symbol {
                        fill: #000 !important;
                    }
                }
            }

        }

        &.ryder-ui--top-bar_dark {
            kendo-appbar {
                kendo-appbar-section {
                    ryui-brand {
                        width: calc(100vw - 120px);
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

    }

    /*-- custom top-bar --*/
    .top-bar {
        background-color: $background-color_2;
        height: 50px;

        .top-bar-menu-nav {
            width: 50px;

            .top-bar-menu-btn {
                border-radius: 100%;
                border: 0px;
                box-shadow: 0 0;
                transition: all .2s;
                background: transparent;
                color: $color_1;
                width: 48px;
                height: 48px;
                padding: 12px;
                font-size: $font-14;
                line-height: 1.4285714286;
                font-family: $font-family_1;
                text-align: center;
                text-decoration: none;
                white-space: nowrap;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                gap: 8px;
                vertical-align: middle;
                user-select: none;
                cursor: pointer;
                outline: none;
                position: relative;
            }
        }

        .top-bar-logo {
            width: 100%;
        }

        .top-bar-user {
            width: 430px;
            padding-right: 10px;
            justify-content: right;

            .username {
                color: $color_6;
                margin-right: 10px;
            }
        }

    }

    .dashboardcontrol-sidenavigation {
        z-index: 9;
        position: absolute;
        height: 100%;
        box-shadow: 1px 0px 4px 0px #9E9E9E;

        // .k-state-selected {

        //     border-radius: 0px !important;
        //     background-color: #CE1126 !important;

        //     .k-ryui-sidenavigation-item-text {
        //         color: #fff !important;
        //     }
        // }

        .k-drawer-item {
            margin: 0px !important;
            padding: 0px !important;
            height: 50px;


            &.k-state-selected {
                .k-ryui-sidenavigation-item-container:not(.nested-option) {
                    border-bottom: none !important;
                }
            }
        }

        .nested-option {
            margin-left: 15px;
        }

        .k-ryui-sidenavigation-item-container:not(.nested-option) {
            border-bottom: 0.25px solid #cccccc61 !important;
        }

        // .k-ryui-sidenavigation-item-text {
        //     color: #000 !important;
        // }

        .k-ryui-sidenavigation-item-text,
        .k-ryui-sidenavigation-item-text-disabled {
            font-family: $font-family;
        }

        .k-ryui-sidenavigation .k-drawer {
            //height: fit-content;
            height: 100%;
        }

        .ryder-ui__large.ryui-material-icon {
            color: $color_2;
        }
    }

    .dashboardcontrol-footer {
        clear: both;
        //margin-top: 50px;
        padding: 0 50px;
        // height: 65px;
        background-color: $background-color_3;
        display: flex;
        align-items: center;

        .logo {
            flex-basis: 18%;
            margin-left: 15%;
        }

        .copyright {
            flex-basis: 45%;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: center;

            .copyright-text {
                color: $color_3;
                font-size: $font-12;
            }

            .links {
                margin-top: 0;
                padding-left: 0;
                display: flex;
                list-style: none;

                li {
                    color: $color_3;
                    font-size: $font-12;
                    text-decoration: underline;
                    cursor: pointer;
                    margin-right: 10px;
                }
            }
        }

        .social-share {
            flex-basis: 30%;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            &>svg {
                cursor: pointer;
                width: 32px;
                height: 32px;
                margin-right: 15px;
            }
        }
    }

}

.dashboardcontrol-routeroutlet {
    width: 100%;
    padding-left: 55px;
}


.dashboard {
    width: 100%;
    background-color: $background-color_3;
    box-shadow: $box-shadow;
    overflow-y: auto;
    margin-top: 3px;

    .table-container {
        overflow-x: auto;
        width: auto;
        padding-left: 20px;
        padding-right: 20px;

        table {
            font-family: $font-family_1;
            border-collapse: collapse;
            width: 100%;
        }

        td {
            border: 1px solid $border-color-1;
            text-align: left;
            padding: 8px;
            white-space: nowrap;
            width: 100%;
        }

        th {
            border: 1px solid $border-color-1;
            text-align: left;
            padding: 8px;
            white-space: nowrap;
        }

        tr.content {
            &:nth-child(odd) {
                background-color: $background-color_1;
            }
        }

        .header {
            background-color: $background-color_2;
            color: $color_1;
            font-size: $font-14;
            font-weight: bold;
            text-align: center;
            height: 35px;
        }

        .content {
            background-color: $background-color_3;
            color: $color_2;
            font-size: $font-14;
            font-weight: normal;
            text-align: left;
            height: 50px;
        }
    }


    .dashboard-container {
        padding-left: 20px;
        padding-right: 20px;

        .h1-heading {
            color: $color_3;
            font-weight: 900;
        }

        .h3-heading {
            color: $color_4;
            font-weight: 500;
        }

        .filter-area {
            display: flex;
            flex-wrap: wrap;
            padding-top: 40px;
            padding-bottom: 40px;
            font-size: $font-14;

            .filter-area-1 {
                width: 400px;
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                color: $color_5;

                .ryder-ui__button-medium button.k-button p {
                    font-family: $font-family;
                }
            }

            .filter-area-2 {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: flex-start;
                row-gap: 20px;
                flex: auto;
                max-height: 140px;

                &>* {
                    margin-right: 20px;
                }

                ryui-text-input {
                    kendo-textbox {
                        height: 38px;
                    }
                }

                ryui-select {
                    .ryder-ui__select-state-default {
                        height: 38px !important;
                    }
                }
            }

        }

        ryui-searchbox {

            .k-ryui-searchbox {
                width: 220px;

                .k-ryui-searchbox-container-empty,
                .k-ryui-searchbox-container {
                    display: flex;
                    flex-direction: row-reverse;
                }

                .k-ryui-searchbox-text {
                    color: $color_3 !important;
                }

                .k-ryui-searchbox-text,
                .k-ryui-searchbox-text-disabled {
                    font-family: $font-family;
                }

                .k-ryui-searchbox-text-2 {
                    display: none;
                }
                .k-ryui-searchbox-icon-search{
                    display: none;
                }

                .k-ryui-searchbox-icon-close {
                    display: none;
                }
            }
        }
    }

    .pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;

        ryui-pagination {
            .ryder-ui--pager-item {
                font-size: $font-14;
                font-family: $font-family;
            }
        }
    }

    .dashboard-table-modal-data {
        overflow-y: scroll;
        max-height: 300px;

        .table-container {
            font-family: $font-family_1;
            font-size: $font-14;
            border-collapse: collapse;
            width: 100%;
            transition: 0.3s;

        }

        .table-container tr.table-row {
            text-align: left;
            padding: 8px;
            height: 40px;
        }

        // .table-container tr.table-row:nth-child(even) {
        //     background-color: $background-color_1;
        // }

        .table-container tr.table-row:hover {
            background-color: $background-color_3;
        }

        .table-container tr th {
            background-color: $background-color_1;
            color: $color_5;
            font-weight: 400;
            width: 140px;
        }
    }

    .ryder-ui--modal--footer {
        display: none !important;
    }

    .ryder-ui--modal--header .k-window-title {
        font-family: $font-family_1 !important;
        font-weight: 600 !important;
    }

    ryui-modal .ryder-ui--modal--body {
        padding-top: 10px;
    }

    ryui-select .ryder-ui__select-default {
        width: 187.7px;
    }

    ryui-date-picker {
        .ryui-date-picker {
            padding: 0px;
        }

        .k-datepicker,
        .k-timepicker {
            width: 213px;
        }
    }

    .main-style span .k-clear-value {
        position: relative;
        top: 7px;
        right: -5px;
    }

}

.premium-checklist {
    width: 100%;
    background-color: $background-color_3;
    box-shadow: $box-shadow;
    overflow-y: auto;
    margin-top: 3px;


    .premium-checklist-container {
        padding-left: 50px;
        padding-right: 50px;

        .h1-heading {
            color: $color_3;
            font-weight: 900;
        }

        .h3-heading {
            color: $color_4;
            font-weight: 500;
        }

        .data-details {


            ul.data-details-list {
                margin: 0;
                padding: 0;
                list-style: none;

                li.strip:nth-child(2) {
                    background-color: $background-color_4;
                }

                .data-details-list-node {
                    min-height: 50px;
                    display: flex;
                    //justify-content: space-between;

                    &>div {
                        border: 1px solid $border-color-1;
                        flex-basis: 24.9%;
                        display: flex;
                        align-items: center;
                        padding: 10px;
                        color: $color_3;
                    }

                    .heading {
                        justify-content: flex-end;
                    }

                    .header-node {
                        background-color: $color_2;
                        color: $color_1;
                    }

                    .comment {
                        margin-left: 10px;
                        flex-grow: 1;
                    }

                    .flex-auto-grow {
                        flex-basis: 50%;
                        flex-grow: 1;
                    }
                }

            }

            .data-details-authorization-code {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $color_7;
                width: 100%;
                height: 70px;

                &>span {
                    color: $color_1;
                    margin-right: 15px;
                }
            }

            .extra-checks {
                display: flex;
                background-color: $background-color_3;
                width: 100%;
                height: 70px;
                color: $color_2;

                &>div {
                    align-items: center;
                    display: flex;
                    flex-basis: calc(75% - 1px);
                    border: 1px solid $border-color-1;
                    padding-left: 15px;
                    padding-right: 15px;

                    .red {
                        font-weight: 400;
                    }

                    &:nth-child(1) {
                        flex-basis: 25%;
                    }
                }

                textarea {
                    width: 100%;
                    height: 50px;
                    padding: 5px;
                    border: 0.25px solid $border-color-1;

                    &:focus-visible {
                        border: 0.25px solid $color_7;
                    }

                    &[disabled] {
                        border-color: transparent;
                    }
                }
            }

        }

        .formAction {

            justify-content: space-around;

            .submit-comment {
                width: 30vw;
                margin-left: 20px;
                margin-right: 20px;
            }
        }

        .data-input {
            .ryder-ui {

                .ryder-ui__input-default {
                    background-color: $color_1;
                }

                /* -- for input label --*/
                .floating-placeholder {
                    top: -22px;
                    left: 0px;
                    font-size: $font-14;
                    background: transparent;
                    padding: 0 2px;
                    opacity: 1;
                }

                /* -- for dropdown label --*/
                .ryder-ui__label {
                    ryui-label {
                        span {
                            color: $color_9;
                            font-size: $font-14;
                        }
                    }
                }
            }
        }
    }

    .eventLog {

        &.table-container {
            overflow-x: auto;
            width: 100%;

            table {
                font-family: $font-family_1;
                border-collapse: collapse;
                width: 100%;
            }

            td {
                border: 1px solid $border-color-1;
                text-align: left;
                padding: 8px;
                white-space: nowrap;
            }

            th {
                border: 1px solid $border-color-1;
                text-align: left;
                padding: 8px;
                white-space: nowrap;
            }

            .header {
                background-color: $background-color_2;
                color: $color_1;
                font-size: $font-14;
                font-weight: bold;
                text-align: center;
                height: 35px;
            }

            .content {
                background-color: $background-color_3;
                color: $color_2;
                font-size: $font-14;
                font-weight: normal;
                text-align: left;
                height: 50px;
            }
        }
    }

    .premium-checklist-modal-data {
        .success {
            color: green;
        }

        .danger {
            color: red;
        }
    }



}

.home {
    min-height: 100vh;
    width: 100%;
    background-color: $background-color_3;
    box-shadow: $box-shadow;
    overflow-y: auto;
    margin-top: 3px;


    .home-container {
        padding-left: 50px;
        padding-right: 50px;

        .h1-heading {
            color: $color_3;
            font-weight: 900;
        }

        .paragaph-1 {
            color: $color_3;
            font-weight: 400;
            width: 35%;
            text-align: center;
        }

        .card-container {
            justify-content: space-evenly;
            margin-top: 100px;
            margin-bottom: 100px;

            .h-card {
                cursor: pointer;
                max-width: 290px;

                .ryui-icon-container {
                    display: flex;
                    justify-content: center;
                }

                .k-icon {
                    font-size: 96px;
                    padding: 60px;

                    svg {
                        path {
                            fill: $color_4;
                        }
                    }
                }

                .h-card-details {
                    background-color: $background-color_1;
                    height: 65px;

                    h4 {
                        text-align: center;
                    }
                }

                .premium-transportation {
                    background-image: url("assets/images/PTA-Icon.png");
                    background-repeat: no-repeat;
                    background-position: center;
                    width: 100%;
                    height: 300px;

                }

                .master-data-management {
                    background-image: url("assets/images/MasterDataMgmt-Icon.png");
                    background-repeat: no-repeat;
                    background-position: center;
                    width: 100%;
                    height: 300px;

                    &:hover {
                        @extend .a-rotate;
                    }
                }

                .user-management {
                    background-image: url("assets/images/UserMGmt-Icon.png");
                    background-repeat: no-repeat;
                    background-position: center;
                    width: 100%;
                    height: 300px;
                }
            }
        }

    }
}

.newptaform {
    width: 100%;
    background-color: $background-color_3;
    box-shadow: $box-shadow;
    overflow-y: auto;
    margin-top: 3px;

    .newptaform-container {
        padding-left: 150px;
        padding-right: 150px;


        .h1-heading {
            color: $color_3;
            font-weight: 900;
        }

        .h3-heading {
            color: $color_4;
            font-weight: 500;
        }

        .newptaform-stepper-container {
            margin-bottom: 50px;
        }

        .all-forms-container {
            width: 50vw;

            .steps-form {

                form {

                    /* hide number field up down arrow */
                    .k-numeric-wrap {
                        .k-select {
                            display: none !important;
                        }
                    }

                    .ryder-ui__helper {
                        .ryder-ui__error {
                            font-size: $font-14;
                        }
                    }

                    .error {
                        font-size: $font-14;
                        margin-top: 5px;
                    }

                    .info-icon {
                        position: absolute;
                        margin-left: 70px;
                    }

                    .ryder-ui {
                        margin-top: 35px;

                        .ryder-ui__input-default {
                            background-color: $color_1;
                        }

                        /* -- for input label --*/
                        .floating-placeholder {
                            top: -22px;
                            left: 0px;
                            font-size: $font-14;
                            background: transparent;
                            padding: 0 2px;
                            opacity: 1;
                        }

                        /* -- for dropdown label --*/
                        .ryder-ui__label {
                            ryui-label {
                                span {
                                    color: $color_9;
                                    font-size: $font-14;
                                }
                            }
                        }
                    }

                    .navigation-btn {

                        ryui-button {
                            &:nth-child(1) {
                                margin-right: 25px;
                            }

                            button {
                                width: 200px;
                                height: 45px;
                            }
                        }
                    }

                    .ryui-date-picker {
                        padding: 0px;

                        .ryui-label {

                            span {
                                font-size: $font-14;
                                color: $color_9;
                            }
                        }

                        .k-datepicker {

                            .k-picker-wrap {
                                height: 47px;
                                padding: 6px 12px;
                            }
                        }

                    }

                    .time-picker {
                        margin-left: 50px;
                    }

                    .time-picker-show {
                        display: flex;
                        align-items: center;
                        margin-top: 20px;
                        margin-left: 10px;
                    }

                    ryui-searchbox {

                        .k-ryui-searchbox {

                            .k-ryui-searchbox-container,
                            .k-ryui-searchbox-container-disabled,
                            .k-ryui-searchbox-container-empty,
                            .k-ryui-searchbox-container-empty-disabled {
                                box-shadow: none !important;
                                border: 0.5px solid $border-color-1;
                            }

                            .k-ryui-searchbox-icon-search {
                                position: absolute;
                                right: 15px;
                                transform: scale(1.1);
                                z-index: 10;
                            }

                            .k-ryui-searchbox-text,
                            .k-ryui-searchbox-text-disabled {
                                width: 45rem !important;
                                height: 48px;
                            }

                        }
                    }

                    .input-with-edit-save {
                        width: calc(100% + 60px);
                        display: flex;

                        ryui-text-input {
                            width: calc(100% - 60px);
                        }

                        .edit-save-action {
                            padding-top: 47px;
                            // position: relative;
                            // left: 100%;
                            // top: -50px;
                            // width: 55px;
                        }
                    }

                    .email-lookup {
                        h3 {
                            color: $color_3;
                            font-size: $font-20;
                            font-weight: 500;
                        }

                        ryui-link {
                            .ryui-link {
                                color: #CF202A;
                                letter-spacing: 0.56px;
                            }
                        }
                    }

                    .input-info-icon-container {

                        ryui-text-input,
                        ryui-textarea {
                            width: 100%;
                        }

                        .info-icon {
                            margin-top: 35px;
                            margin-left: 49vw;
                        }

                    }

                }
            }
        }
    }


    .pta-DUNS-modal {
        table {
            border-collapse: collapse;
            width: 100%;

            th,
            td {
                border: 1px solid #ddd;
                padding: 8px;
                text-align: left;
            }

            th {
                background-color: #f0f0f0;
            }
        }

        input[type="radio"] {
            transform: scale(1.3);
        }

        .modal-btn {
            justify-content: flex-end;

            ryui-button {
                margin-left: 20px;
            }
        }
    }

    .pta-emaillookup-modal {
        h3 {
            color: $color_3;
            font-size: $font-18;
            font-weight: 500;
        }

        ryui-searchbox {

            .k-ryui-searchbox {

                .k-ryui-searchbox-container,
                .k-ryui-searchbox-container-disabled,
                .k-ryui-searchbox-container-empty,
                .k-ryui-searchbox-container-empty-disabled {
                    box-shadow: none !important;
                    border: 0.5px solid $border-color-1;
                }

                .k-ryui-searchbox-icon-search {
                    position: absolute;
                    right: 15px;
                    transform: scale(1.1);
                }

                .k-ryui-searchbox-text,
                .k-ryui-searchbox-text-disabled {
                    width: 32rem !important;
                    height: 48px;
                }

            }

        }

        table {
            border-collapse: collapse;
            width: 100%;

            th,
            td {
                border-bottom: 1px solid #ddd;
                padding: 15px;
                text-align: left;
                font-size: $font-14;
            }

            th {
                color: #666;
                font-weight: 500;
                //background-color: #f0f0f0;
            }

            td {
                color: #000;

                &:nth-child(2) {
                    color: #CF202A;
                    font-weight: 400;
                    text-decoration-line: underline;
                }
            }
        }

        input[type="radio"] {
            transform: scale(1.3);
        }

        .modal-btn {
            justify-content: flex-end;

            ryui-button {
                margin-left: 20px;
            }
        }
    }

}


.ptaconfig {
    width: 100%;
    background-color: $background-color_3;
    box-shadow: $box-shadow;
    overflow-y: auto;
    margin-top: 3px;

    .ptaconfig-container {
        padding-left: 150px;
        padding-right: 150px;
        height: 100vh;
    }

    .ptaconfig-box {
        padding: 10px 25px;
        background-color: $background-color_1;
        background-color: #F8F8F8;
        box-shadow: $box-shadow;
        width: 600px;

        .h1-heading {
            color: $color_3;
            font-weight: 600;
        }

        .h3-heading {
            color: $color_4;
            font-weight: 400;
        }

        ul.radio-list {
            list-style: none;
            padding-left: 0px;

            li {
                display: flex;
                align-items: center;
                font-size: $font-14;

                input {
                    margin-bottom: 10px;
                    margin-right: 10px;
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .note {
            font-size: $font-14;
            color: $color_7;
            font-style: italic;

            b {
                color: $color_4;
                cursor: pointer;
            }
        }

        .file-info {
            text-align: left;

            &.success {
                color: $color_10;
            }

            &.fail {
                color: $color_4;
            }
        }
    }



}

.unauthorized-container {
    text-align: center;

    .unauthorized {
        font-size: 48px;
        color: $color_4;
        text-align: center;
        margin-top: 20%;
    }

    .nav {
        color: $color_10;
        font-size: 16px;
        padding: 50px;
        cursor: pointer;
        text-decoration: underline;
    }
}

.usermgmt {
    width: 100%;
    background-color: $background-color_3;
    box-shadow: $box-shadow;
    margin-top: 3px;
    min-height: 100vh;

    .table-container {
        overflow-x: auto;
        width: auto;
        padding-left: 20px;
        padding-right: 20px;

        table {
            font-family: $font-family_1;
            border-collapse: collapse;
            width: 100%;
        }

        td {
            border-bottom: 1px solid $border-color-1;
            text-align: left;
            padding: 8px;
            word-wrap: break-word;
            max-width: 15.5vw;
        }

        th {
            border: 1px solid $border-color-1;
            text-align: left;
            padding: 8px;
            white-space: nowrap;
        }

        tr.content {
            &:nth-child(odd) {
                background-color: $background-color_1;
            }
        }

        .header {
            background-color: $color_7;
            color: $color_1;
            font-size: $font-14;
            font-weight: bold;
            text-align: center;
            height: 35px;
        }

        .content {
            background-color: $background-color_3;
            color: $color_2;
            font-size: $font-14;
            font-weight: normal;
            text-align: left;
            height: 50px;

            &.disabled{
                td:not(td.flex){
                    opacity: 0.5;
                }
                td.flex > ryui-button:nth-child(1){
                    opacity: 0.5;
                    pointer-events: none;
                }
            }
        }
    }


    .usermgmt-container {
        padding-left: 20px;
        padding-right: 20px;

        .h1-heading {
            color: $color_3;
            font-weight: 900;
        }

        .h3-heading {
            color: $color_4;
            font-weight: 500;
        }

        .filter-area {
            display: flex;
            flex-wrap: wrap;
            padding-top: 40px;
            padding-bottom: 40px;
            font-size: $font-14;

            .filter-area-1 {
                width: 335px;
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                color: $color_5;

                .ryder-ui__button-medium button.k-button p {
                    font-family: $font-family;
                }
            }

            .filter-area-2 {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: flex-start;
                row-gap: 20px;
                flex: auto;
                max-height: 140px;

                &>* {
                    margin-right: 20px;
                }

                ryui-text-input {
                    kendo-textbox {
                        height: 38px;
                    }
                }

                ryui-select {
                    .ryder-ui__select-state-default {
                        height: 38px !important;
                    }
                }
                ryui-multi-select{
                    kendo-searchbar{
                        input{
                            height: 38px !important;
                        }
                    }
                }
            }

        }

        ryui-searchbox {

            .k-ryui-searchbox {
                width: 220px;

                .k-ryui-searchbox-container-empty,
                .k-ryui-searchbox-container {
                    display: flex;
                    flex-direction: row-reverse;
                }

                .k-ryui-searchbox-text {
                    color: $color_3 !important;
                }

                .k-ryui-searchbox-text,
                .k-ryui-searchbox-text-disabled {
                    font-family: $font-family;
                }

                .k-ryui-searchbox-text-2 {
                    display: none;
                }

                .k-ryui-searchbox-icon-close {
                    display: none;
                }
            }
        }
    }

    .pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;

        ryui-pagination {
            .ryder-ui--pager-item {
                font-size: $font-14;
                font-family: $font-family;
            }
        }
    }

    .usermgmt-table-modal-data {
        overflow-y: scroll;
        //max-height: 300px;
        height: 80vh;
    }

    .ryder-ui--modal--footer {
        display: none !important;
    }

    .ryder-ui--modal--header .k-window-title {
        font-family: $font-family_1 !important;
        font-weight: 600 !important;
    }

    ryui-modal .ryder-ui--modal--body {
        padding-top: 10px;
    }

    ryui-select .ryder-ui__select-default {
        width: 187.7px;
    }

    ryui-date-picker {
        .ryui-date-picker {
            padding: 0px;
        }

        .k-datepicker,
        .k-timepicker {
            width: 213px;
        }
    }

    .main-style span .k-clear-value {
        position: relative;
        top: 7px;
        right: -5px;
    }

    .steps-form {

        form {

            /* hide number field up down arrow */
            .k-numeric-wrap {
                .k-select {
                    display: none !important;
                }
            }

            .ryder-ui__helper {
                .ryder-ui__error {
                    font-size: $font-14;
                }
            }

            .error {
                font-size: $font-14;
                margin-top: 5px;
            }

            .info-icon {
                position: absolute;
                margin-left: 70px;
            }

            .ryder-ui {
                margin-top: 35px;

                .ryder-ui__input-default {
                    background-color: $color_1;
                }

                /* -- for input label --*/
                .floating-placeholder {
                    top: -22px;
                    left: 0px;
                    font-size: $font-14;
                    background: transparent;
                    padding: 0 2px;
                    opacity: 1;
                }

                /* -- for dropdown label --*/
                .ryder-ui__label {
                    ryui-label {
                        span {
                            color: $color_9;
                            font-size: $font-14;
                        }
                    }
                }
            }

            .navigation-btn {

                ryui-button {
                    &:nth-child(1) {
                        margin-right: 25px;
                    }

                    button {
                        width: 200px;
                        height: 45px;
                    }
                }
            }

            .ryui-date-picker {
                padding: 0px;

                .ryui-label {

                    span {
                        font-size: $font-14;
                        color: $color_9;
                    }
                }

                .k-datepicker {

                    .k-picker-wrap {
                        height: 47px;
                        padding: 6px 12px;
                    }
                }

            }

            .time-picker {
                margin-left: 50px;
            }

            .time-picker-show {
                display: flex;
                align-items: center;
                margin-top: 20px;
                margin-left: 10px;
            }

            ryui-searchbox {

                .k-ryui-searchbox {

                    .k-ryui-searchbox-container,
                    .k-ryui-searchbox-container-disabled,
                    .k-ryui-searchbox-container-empty,
                    .k-ryui-searchbox-container-empty-disabled {
                        box-shadow: none !important;
                        border: 0.5px solid $border-color-1;
                    }

                    .k-ryui-searchbox-icon-search {
                        position: absolute;
                        right: 15px;
                        transform: scale(1.1);
                    }

                    .k-ryui-searchbox-text,
                    .k-ryui-searchbox-text-disabled {
                        width: 45rem !important;
                        height: 48px;
                    }

                }
            }

            .input-with-edit-save {
                width: calc(100% + 60px);
                display: flex;

                ryui-text-input {
                    width: calc(100% - 60px);
                }

                .edit-save-action {
                    padding-top: 47px;
                    // position: relative;
                    // left: 100%;
                    // top: -50px;
                    // width: 55px;
                }
            }

            .email-lookup {
                h3 {
                    color: $color_3;
                    font-size: $font-20;
                    font-weight: 500;
                }

                ryui-link {
                    .ryui-link {
                        color: #CF202A;
                        letter-spacing: 0.56px;
                    }
                }
            }

            .input-info-icon-container {

                ryui-text-input,
                ryui-textarea {
                    width: 100%;
                }

                .info-icon {
                    margin-top: 35px;
                    margin-left: 49vw;
                }

            }

            ryui-multi-select {
                kendo-multiselect{
                    kendo-searchbar{
                        input{
                            height: 50px !important;
                        }
                    }
                    kendo-popup{
                        kendo-list{
                            ul{
                                li.k-item{
                                    ryui-checkbox{
                                        .ryder-ui--container{
                                            input.ryder-ui{
                                                margin-top: 0px!important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                
            }

        }
    }

    .usermgmt-modal-data {
        .success {
            color: green;
        }

        .fail {
            color: red;
        }

        p{
            text-align: center;
        }

        .promtmodal-list{
            width: 64%;
            ul{
                padding-left:0px;
                list-style: none;

                li{
                    span{
                        color: $color_9;
                    }
                }
            }
        }
    }

}